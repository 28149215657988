import { all, call, select, put, takeLeading } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { fromJS } from 'immutable';
import { apiRequest } from 'utils/request';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';

import {
  PROFILE_LIST_FILTER_REQUEST,
  PROFILE_LIST_REQUEST,
  PROFILE_READ_REQUEST,
  CHANGE_REPORTS_NAME,
  CHANGE_REPORTS_PAGE,
  CHANGE_REPORTS_SORT,
  PROFILE_REPORTS_REQUEST,
  CREATE_COMPARE_REQUEST,
  PROFILE_CREATE_FROM_SAMPLE_REQUEST,
  CHANGE_STAFF_INPUT,
  STAFF_LIST_REQUEST,
  CHANGE_STAFF_NEGATIVE_INPUT,
  POSITIVE_STAFF_LIST_REQUEST,
  CHANGE_STAFF_POSITIVE_INPUT,
  NEGATIVE_STAFF_LIST_REQUEST,
  PROFILE_UPDATE_REQUEST,
  QUESTIONS_LIST_REQUEST,
  PROFILE_CREATE_FROM_QUESTIONS_REQUEST,
  CHANGE_PROFILE_FOR_UPDATE,
  DELETE_PROFILE_REQUEST,
  META_PROGRAMS_LIST_REQUEST,
  PROFILE_CREATE_CUSTOM_REQUEST,
  CHANGE_NAME_FILTER,
  CHANGE_MANAGERS_FILTER,
  CHANGE_PROFILE_PAGE,
  CHANGE_PROFILE_SORT,
  SEND_PROFILE_CHIEF_REQUEST,
  SEND_PROFILE_EMAIL_REQUEST
} from './constants';

import {
  loadingTableFinish,
  profileFilteredList,
  profileList,
  profileListSuccess,
  profileListFail,
  profileReadSuccess,
  profileReadFail,
  profileReports,
  profileReportsSuccess,
  profileReportsFail,
  profileCreateFromSampleSuccess,
  profileCreateFromSampleFail,
  profileUpdateSuccess,
  profileUpdateFail,
  questionListSuccess,
  questionListFail,
  staffListSuccess,
  staffListFail,
  negativeStaffListSuccess,
  negativeStaffListFail,
  positiveStaffListSuccess,
  positiveStaffListFail,
  profileCreateFromQuestionsSuccess,
  profileCreateFromQuestionsFail,
  profileUpdate,
  deleteProfileSuccess,
  deleteProfileFail,
  metaProgramsListSuccess,
  metaProgramsListFail,
  profileCreateCustomSuccess,
  profileCreateCustomFail,
  createCompareSuccess,
  createCompareFail,
  sendProfileChiefSuccess,
  sendProfileChiefFail,
  sendProfileEmailSuccess,
  sendProfileEmailFail
} from './actions';

import {
  makeSelectProfileid,
  makeSelectProfileRead,
  makeSelectNameFromSample,
  makeSelectDescFromSample,
  makeSelectNegativeFromSample,
  makeSelectManagersFilter,
  makeSelectPositiveFromSample,
  makeSelectProfileQuestions,
  makeSelectProfileQuestionsName,
  makeSelectProfileQuestionsDesc,
  makeSelectProfileCreated,
  makeSelectProfileForUpdate,
  makeSelectProfileCustom,
  makeSelectNameFilter,
  makeSelectProfilePage,
  makeSelectSort,
  makeSelectProfileReportsFilter,
  makeSelectProfileReportsPage,
  makeSelectProfileReportsSort,
  makeSelectProfileId,
  makeSelectStaffCompare,
  makeSelectStaffInput,
  makeSelectNegativeInput,
  makeSelectPositiveInput,
  makeSelectProfileChief,
  makeSelectProfileEmail
} from './selectors';

function* updateProfileFilteredSaga() {
  yield put(profileFilteredList());
}

function* profileListSaga() {
  const url = 'api/managers/profiles/';

  try {
    const request = yield call(apiRequest, url);

    const profileList = fromJS(request.data.results);

    yield put(
      profileListSuccess({
        profileCount: request.data.count,
        profileList
      })
    );
  } catch (e) {
    yield put(profileListFail());
  }
}

function* profileFilteredListSaga() {
  const managerFilter = yield select(makeSelectManagersFilter());
  const nameFilter = yield select(makeSelectNameFilter());
  const page = yield select(makeSelectProfilePage());
  const sort = yield select(makeSelectSort());
  const offset = page * 25 - 25;
  const params = {};

  if (managerFilter !== 0) {
    params.manager = managerFilter;
  }

  if (nameFilter !== '') {
    params.name = nameFilter;
  }

  if (page > 1) {
    params.offset = offset;
  }

  if (sort.get('key') !== '') {
    params.ordering = sort.get('order') === 'DESC' ? `-${sort.get('key')}` : sort.get('key');
  }

  const url = `api/managers/profiles/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    const profileList = fromJS(request.data.results);

    yield put(
      profileListSuccess({
        profileCount: request.data.count,
        profileList
      })
    );
  } catch (e) {
    yield put(profileListFail());
  } finally {
    yield put(loadingTableFinish());
  }
}

function* profileReadSaga() {
  const profileId = yield select(makeSelectProfileid());
  const url = `api/managers/profiles/${profileId}/`;

  try {
    const request = yield call(apiRequest, url);
    const data = request.data;
    const patterns = data.pattern_ranges;
    const patternIds = patterns.map(item => item.pattern);

    const metaprograms = data.meta_programs
      .map(program => ({
        ...program,
        patterns: program.patterns.reduce((result, pattern) => {
          if (indexOf(patternIds, pattern.id) !== -1) {
            const patternFromPatterns = patterns.find(item => item.pattern === pattern.id);

            result.push({
              id: pattern.id,
              description: pattern.description,
              name: pattern.name,
              position: pattern.position,
              is_important: patternFromPatterns.is_important,
              range: patternFromPatterns.range
            });
          }

          return result;
        }, [])
      }))
      .filter(program => !isEmpty(program.patterns));

    const profileRead = fromJS({
      ...data,
      newName: data.name,
      meta_programs: metaprograms
    });
    yield put(
      profileReadSuccess({
        profileRead
      })
    );
  } catch (e) {
    yield put(profileReadFail());
  } finally {
    yield put(profileReports());
  }
}

function* profileReportsSaga() {
  const profileId = yield select(makeSelectProfileid());
  const page = yield select(makeSelectProfileReportsPage());
  const sort = yield select(makeSelectProfileReportsSort());
  const filter = yield select(makeSelectProfileReportsFilter());
  const offset = page * 25 - 25;
  const params = {};

  if (filter !== '') {
    params.profile_name = filter;
  }

  if (page > 1) {
    params.offset = offset;
  }

  if (sort.get('key') !== '') {
    params.ordering = sort.get('order') === 'DESC' ? `-${sort.get('key')}` : sort.get('key');
  }

  const url = `api/managers/profiles/${profileId}/profile_conformance_reports/?${Object.entries(
    params
  )
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);

    yield put(
      profileReportsSuccess({
        profileReportsCount: request.data.count,
        profileReports: fromJS(request.data.results)
      })
    );
  } catch (e) {
    yield put(profileReportsFail());
  }
}

function* createCompareSaga() {
  const url = 'api/managers/profile_conformance_reports/';
  const profileId = yield select(makeSelectProfileId());
  const staffsId = yield select(makeSelectStaffCompare());

  const data = staffsId.map(staff => ({
    staff: staff,
    profile: profileId
  }));
  const parallelActions = [];

  let i = 0;
  while (i < data.length) {
    const options = {
      method: 'post',
      data: data[i]
    };

    parallelActions[i] = call(apiRequest, url, options);
    i++;
  }
  try {
    yield all(parallelActions);
    yield put(createCompareSuccess());
  } catch (e) {
    yield put(createCompareFail());
  } finally {
    yield put(profileReports());
  }
}

function* profileCreateFromSampleSaga() {
  const url = 'api/managers/profiles/create_from_samples/';

  const name = yield select(makeSelectNameFromSample());
  const desc = yield select(makeSelectDescFromSample());
  const negative = yield select(makeSelectNegativeFromSample());
  const positive = yield select(makeSelectPositiveFromSample());
  const options = {
    method: 'post',
    data: {
      name,
      description: desc,
      positive_samples: positive.map(item => item.value),
      negative_samples: negative.map(item => item.value)
    }
  };

  try {
    const request = yield call(apiRequest, url, options);
    const data = request.data;
    const patterns = data.pattern_ranges;
    const patternIds = patterns.map(item => item.pattern);

    const metaprograms = data.meta_programs
      .map(program => ({
        ...program,
        patterns: program.patterns.reduce((result, pattern) => {
          if (indexOf(patternIds, pattern.id) !== -1) {
            result.push({
              id: pattern.id,
              description: pattern.description,
              name: pattern.name,
              position: pattern.position,
              is_important: pattern.is_important,
              range: patterns.filter(item => item.pattern === pattern.id)[0].range
            });
          }

          return result;
        }, [])
      }))
      .filter(program => !isEmpty(program.patterns));

    const profileCreated = fromJS({
      ...data,
      meta_programs: metaprograms
    });

    yield put(
      profileCreateFromSampleSuccess({
        profileCreated
      })
    );
    yield put(push('/profile/create/compare/2'));
  } catch (e) {
    yield put(profileCreateFromSampleFail());
  }
}

function* staffListSaga() {
  const nameFilter = yield select(makeSelectStaffInput());

  const params = {
    is_tested: 1,
    ordering: 'full_name',
    limit: 25
  };
  if (nameFilter !== '') {
    params.full_name = nameFilter;
    params.limit = 25;
  }

  const url = `api/managers/staff/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);
    const staffList = fromJS(request.data.results);

    yield put(
      staffListSuccess({
        staffList
      })
    );
  } catch (e) {
    yield put(staffListFail());
  }
}

function* positiveStaffSaga() {
  const nameFilter = yield select(makeSelectPositiveInput());

  const params = {
    is_tested: 1,
    ordering: 'full_name',
    limit: 25
  };
  if (nameFilter !== '') {
    params.full_name = nameFilter;
    params.limit = 25;
  }

  const url = `api/managers/staff/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);
    const list = request.data.results;

    yield put(
      positiveStaffListSuccess({
        list
      })
    );
  } catch (e) {
    yield put(positiveStaffListFail());
  }
}

function* negativeStaffSaga() {
  const nameFilter = yield select(makeSelectNegativeInput());

  const params = {
    is_tested: 1,
    ordering: 'full_name',
    limit: 25
  };
  if (nameFilter !== '') {
    params.full_name = nameFilter;
    params.limit = 25;
  }

  const url = `api/managers/staff/?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;

  try {
    const request = yield call(apiRequest, url);
    const list = request.data.results;

    yield put(
      negativeStaffListSuccess({
        list
      })
    );
  } catch (e) {
    yield put(negativeStaffListFail());
  }
}

function* profileUpdateSaga() {
  const profileType = yield select(makeSelectProfileForUpdate());

  let profile = yield select(makeSelectProfileRead());
  let name = profile.get('newName');
  if (profileType === 'created') {
    profile = yield select(makeSelectProfileCreated());
    name = profile.get('name');
  }

  const url = `api/managers/profiles/${profile.get('id')}/`;

  const patternRanges = [];
  profile.get('meta_programs').map(metaprogram =>
    metaprogram.get('patterns').map(pattern =>
      patternRanges.push({
        pattern: pattern.get('id'),
        is_important: pattern.get('is_important'),
        range: {
          lower: pattern.getIn(['range', 'lower']),
          upper: pattern.getIn(['range', 'upper'])
        }
      })
    )
  );

  const options = {
    method: 'put',
    data: {
      name,
      description: profile.get('description'),
      pattern_ranges: patternRanges
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(profileUpdateSuccess());
  } catch (e) {
    yield put(profileUpdateFail());
  } finally {
    yield put(push('/profile/list'));
  }
}

function* questionsListSaga() {
  const url = 'api/managers/questions/';

  try {
    const request = yield call(apiRequest, url);
    const answers = [];
    request.data.map(question =>
      answers.push({
        id: question.id,
        text: question.text,
        answers: question.answers.map(answer => ({
          id: answer.id,
          text: answer.text,
          value: false
        }))
      })
    );

    yield put(
      questionListSuccess({
        answers: fromJS(answers)
      })
    );
  } catch (e) {
    yield put(questionListFail());
  }
}

function* profileCreateFromQuestionsSaga() {
  const url = 'api/managers/profiles/create_from_questionnaire/';
  const name = yield select(makeSelectProfileQuestionsName());
  const description = yield select(makeSelectProfileQuestionsDesc());
  const profile = yield select(makeSelectProfileQuestions());

  const options = {
    method: 'post',
    data: {
      name,
      description,
      questions: profile
        .get('answers')
        .map(question => ({
          question: question.get('id'),
          answers: question.get('answers').reduce((result, answer) => {
            if (answer.get('value') === true) {
              result.push(answer.get('id'));
            }

            return result;
          }, [])
        }))
        .toJS()
    }
  };

  try {
    const request = yield call(apiRequest, url, options);
    const data = request.data;
    const patterns = data.pattern_ranges;
    const patternIds = patterns.map(item => item.pattern);

    const metaprograms = data.meta_programs
      .map(program => ({
        ...program,
        patterns: program.patterns.reduce((result, pattern) => {
          if (indexOf(patternIds, pattern.id) !== -1) {
            result.push({
              id: pattern.id,
              description: pattern.description,
              name: pattern.name,
              position: pattern.position,
              is_important: pattern.is_important,
              range: patterns.filter(item => item.pattern === pattern.id)[0].range
            });
          }

          return result;
        }, [])
      }))
      .filter(program => !isEmpty(program.patterns));

    const profileCreated = fromJS({
      ...data,
      meta_programs: metaprograms
    });

    yield put(
      profileCreateFromQuestionsSuccess({
        profileCreated
      })
    );
    yield put(push('/profile/create/ask/3'));
  } catch (e) {
    yield put(profileCreateFromQuestionsFail());
  }
}

function* changeProfileForUpdateSaga() {
  yield put(profileUpdate());
}

function* deleteProfileSaga() {
  const profileId = yield select(makeSelectProfileid());
  const url = `api/managers/profiles/${profileId}/`;
  const options = {
    method: 'delete'
  };

  try {
    yield call(apiRequest, url, options);

    yield put(deleteProfileSuccess());
    yield put(profileList());
  } catch (e) {
    yield put(deleteProfileFail());
  }
}

function* metaProgramsListSaga() {
  const url = 'api/managers/meta_programs/';

  try {
    const request = yield call(apiRequest, url);
    const metaProgramsList = fromJS(request.data).sortBy(metaProgram =>
      metaProgram.get('position')
    );
    const patternRanges = [];

    metaProgramsList.map(metaProgram =>
      metaProgram
        .get('patterns')
        .sortBy(pattern => pattern.get('position'))
        .map(pattern =>
          patternRanges.push({
            metaProgramId: metaProgram.get('id'),
            id: pattern.get('id'),
            name: pattern.get('name'),
            description: pattern.get('description'),
            is_important: false,
            range: {
              lower: 35,
              upper: 65
            }
          })
        )
    );

    yield put(
      metaProgramsListSuccess({
        metaProgramsList,
        patternRanges: fromJS(patternRanges)
      })
    );
  } catch (e) {
    yield put(metaProgramsListFail());
  }
}

function* profileCreateCustomSaga() {
  const url = 'api/managers/profiles/';
  const profile = yield select(makeSelectProfileCustom());
  const options = {
    method: 'post',
    data: {
      name: profile.get('name'),
      description: profile.get('description'),
      pattern_ranges: profile
        .get('pattern_ranges')
        .map(pattern => ({
          pattern: pattern.get('id'),
          is_important: pattern.get('is_important'),
          range: {
            lower: pattern.getIn(['range', 'lower']),
            upper: pattern.getIn(['range', 'upper'])
          }
        }))
        .toJS()
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(profileCreateCustomSuccess());
  } catch (e) {
    yield put(profileCreateCustomFail());
  } finally {
    yield put(push('/profile/list'));
  }
}

function* sendProfileChiefSaga() {
  const url = 'api/managers/profile_create_invitations/';
  const profile = yield select(makeSelectProfileChief());
  const data = {
    profile_name: profile.name,
    profile_description: profile.desc,
    email: profile.email,
    full_name: profile.fullName,
    message: profile.message
  };

  const options = {
    method: 'post',
    data
  };

  try {
    yield call(apiRequest, url, options);

    yield put(sendProfileChiefSuccess());
    yield put(push('/profile/list'));
  } catch (e) {
    yield put(sendProfileChiefFail(e));
  }
}

function* sendProfileEmailSaga() {
  const profile = yield select(makeSelectProfileEmail());
  const url = `api/managers/profiles/${profile.profileId}/send_heatmap_email/`;

  const options = {
    method: 'post',
    data: {
      to_email: profile.email
    }
  };

  try {
    yield call(apiRequest, url, options);

    yield put(sendProfileEmailSuccess());
  } catch (e) {
    yield put(sendProfileEmailFail());
  }
}

export default function* profileSagas() {
  yield takeLeading(PROFILE_LIST_REQUEST, profileListSaga);
  yield takeLeading(PROFILE_LIST_FILTER_REQUEST, profileFilteredListSaga);
  yield takeLeading(PROFILE_READ_REQUEST, profileReadSaga);
  yield takeLeading(PROFILE_UPDATE_REQUEST, profileUpdateSaga);
  yield takeLeading(PROFILE_CREATE_FROM_SAMPLE_REQUEST, profileCreateFromSampleSaga);
  yield takeLeading(STAFF_LIST_REQUEST, staffListSaga);
  yield takeLeading(CHANGE_STAFF_INPUT, staffListSaga);
  yield takeLeading(POSITIVE_STAFF_LIST_REQUEST, positiveStaffSaga);
  yield takeLeading(CHANGE_STAFF_POSITIVE_INPUT, positiveStaffSaga);
  yield takeLeading(NEGATIVE_STAFF_LIST_REQUEST, negativeStaffSaga);
  yield takeLeading(CHANGE_STAFF_NEGATIVE_INPUT, negativeStaffSaga);
  yield takeLeading(QUESTIONS_LIST_REQUEST, questionsListSaga);
  yield takeLeading(PROFILE_CREATE_FROM_QUESTIONS_REQUEST, profileCreateFromQuestionsSaga);
  yield takeLeading(CHANGE_PROFILE_FOR_UPDATE, changeProfileForUpdateSaga);
  yield takeLeading(DELETE_PROFILE_REQUEST, deleteProfileSaga);
  yield takeLeading(META_PROGRAMS_LIST_REQUEST, metaProgramsListSaga);
  yield takeLeading(PROFILE_CREATE_CUSTOM_REQUEST, profileCreateCustomSaga);
  yield takeLeading(CHANGE_MANAGERS_FILTER, updateProfileFilteredSaga);
  yield takeLeading(CHANGE_NAME_FILTER, updateProfileFilteredSaga);
  yield takeLeading(CHANGE_PROFILE_PAGE, updateProfileFilteredSaga);
  yield takeLeading(CHANGE_PROFILE_SORT, updateProfileFilteredSaga);
  yield takeLeading(CHANGE_REPORTS_SORT, profileReportsSaga);
  yield takeLeading(CHANGE_REPORTS_PAGE, profileReportsSaga);
  yield takeLeading(CHANGE_REPORTS_NAME, profileReportsSaga);
  yield takeLeading(PROFILE_REPORTS_REQUEST, profileReportsSaga);
  yield takeLeading(CREATE_COMPARE_REQUEST, createCompareSaga);
  yield takeLeading(SEND_PROFILE_CHIEF_REQUEST, sendProfileChiefSaga);
  yield takeLeading(SEND_PROFILE_EMAIL_REQUEST, sendProfileEmailSaga);
}
